'use client';

import { observer } from 'mobx-react-lite';
import type { FunctionComponent } from 'react';
import { useBrandLocaleValue } from '@/react/hooks/useBrandLocaleValue';
import type { IMainProps } from './IMainProps';

import DefaultMainLayout from './DefaultMainLayout';

/**
 * The main layout of the website with header, footer and content.
 *
 * @param props - Contains the content children, and CoreMedia Data.
 */
const Main = observer(function Main({
  children,
  navigationContent,
  hideFooterNavigation,
  page,
  paddingBottom
}: IMainProps) {
  const BrandSpecificMainLayout = useBrandLocaleValue<
    FunctionComponent<IMainProps>
  >(
    () => ({
      default: DefaultMainLayout
    }),
    []
  );

  return (
    <BrandSpecificMainLayout
      navigationContent={navigationContent}
      page={page}
      hideFooterNavigation={hideFooterNavigation}
      paddingBottom={paddingBottom}
    >
      {children}
    </BrandSpecificMainLayout>
  );
});

export default Main;
