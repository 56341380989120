'use client';

import { useRouter } from '@/react/utils/router-utils/useRouter';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';

import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import UserService from '@/services/isomorphic/UserService';

import { classes } from '@/next-utils/css-utils/scss-utils';
import { withEcommerceEnabled } from '@/react/components/utility/EcommerceEnabled';
import { useBrandLocaleValue } from '@/react/hooks/useBrandLocaleValue';
import { useGlobalContext } from '@/react/hooks/useGlobalContext';
import { msg } from '@/services/isomorphic/I18NService';
import { EventType } from '@/services/isomorphic/UserInteractionService';
import { useLogout } from '@/react/hooks/useLogout';
import {
  Breakpoint,
  Breakpoints,
  Default
} from '../../../../core-ui/Breakpoints';
import { Button } from '../../../../core-ui/Button';
import { Icon, IconSizeProp, IconTypes } from '../../../../core-ui/Icon';
import { Link } from '../../../../core-ui/Link';
import { Spinner } from '../../../../core-ui/Spinner';
import { HoverIntent } from '../../../../utility/HoverIntent';

import S from './styles.base.module.scss';
import { header_utilBar_accountIconAltText } from "@/lang/__generated__/ahnu/header_utilBar_accountIconAltText";
import { header_utilBar_viewAccount } from "@/lang/__generated__/ahnu/header_utilBar_viewAccount";
import { order_orderHistory_title } from "@/lang/__generated__/ahnu/order_orderHistory_title";
import { account_authenticated_logout } from "@/lang/__generated__/ahnu/account_authenticated_logout";
import { login_title } from "@/lang/__generated__/ahnu/login_title";
import { account_createAccount_title } from "@/lang/__generated__/ahnu/account_createAccount_title";

/**
 * Header button for the user account navigation.
 * Displays dropdown with links to account pages, or links to /account page on mobile.
 * @returns Icon button with dropdown.
 */
const UserButton: FunctionComponent = () => {
  const { isLoggedIn, logOut, loading } = useLogout();

  const [open, setOpen] = useState(false);

  const doOpen = useCallback(() => setOpen(true), []);
  const doClose = useCallback(() => setOpen(false), []);

  const handleLogOut = useCallback(async () => {
    setOpen(false);
    await logOut();
  }, [logOut]);

  // Determine which type of user Account icon to use for the brand.
  const UserIconType = useBrandLocaleValue<IconTypes>(
    () => ({
      default: IconTypes.User,
      AHNU: IconTypes.UserLight
    }),
    []
  );

  // Check to see if the brand has a custom icon size for the button icon.
  const UserIconSize = useBrandLocaleValue<IconSizeProp>(
    () => ({
      default: IconSizeProp.SizeLG
    }),
    []
  );

  const button = (
    <button
      type="button"
      onFocus={doOpen}
      onBlur={doClose}
      className={S.buttonWithIcon}
      aria-label={msg(header_utilBar_accountIconAltText)}
    >
      <Icon icon={UserIconType} size={UserIconSize} />
    </button>
  );

  const dropdown = (
    <div
      className={classes(S.dropdown, {
        // The `visible` class will be applied only when
        // `open` is true.
        [S.visible]: open
      })}
    >
      {loading ? (
        /** Show spinner while loading user status. */
        <Spinner className={S.spinner} />
      ) : (
        <ul className={S.links}>
          {isLoggedIn ? (
            <>
              <li className={S.linkWrapper} key="accountView">
                <Link
                  href="/account"
                  variant="text"
                  className={S.link}
                  datatestID="usrAccView"
                >
                  {msg(header_utilBar_viewAccount)}
                </Link>
              </li>
              <li className={S.linkWrapper} key="accountOrders">
                <Link
                  href="/orders"
                  variant="text"
                  className={S.link}
                  datatestID="usrOrdHistory"
                >
                  {msg(order_orderHistory_title)}
                </Link>
              </li>
              <li className={S.linkWrapper} key="accountLogout">
                <Button
                  onClick={handleLogOut}
                  variant="text"
                  className={S.link}
                  datatestID="usrAccLogout"
                  interactionDetails={{ action: EventType.UserSignout }}
                >
                  {msg(account_authenticated_logout)}
                </Button>
              </li>
            </>
          ) : (
            <>
              <li className={S.linkWrapper} key="accountLogin">
                <Link
                  href="/login"
                  variant="text"
                  className={S.link}
                  datatestID="usrAccLogin"
                >
                  {msg(login_title)}
                </Link>
              </li>
              <li className={S.linkWrapper} key="accountCreate">
                <Link
                  href="/login?tab=1"
                  variant="text"
                  className={S.link}
                  datatestID="usrAccCreate"
                >
                  {msg(account_createAccount_title)}
                </Link>
              </li>
            </>
          )}
        </ul>
      )}
    </div>
  );

  return (
    <Breakpoints>
      <Breakpoint media="desktop">
        <HoverIntent onHoverIn={doOpen} onHoverOut={doClose}>
          <div className={S.wrapper}>
            {button}
            {dropdown}
          </div>
        </HoverIntent>
      </Breakpoint>
      <Default>
        <Link href="/account" variant="text" className={S.wrapper}>
          {button}
        </Link>
      </Default>
    </Breakpoints>
  );
};

export default withEcommerceEnabled(UserButton);
