'use client';

import { FunctionComponent, useCallback } from 'react';

import { useRouter } from '@/react/utils/router-utils/useRouter';
import { msg } from '@/services/isomorphic/I18NService';
import { ReturnsFlowStateModel } from '@/services/models/Returns/flow';
import { InvalidStateError } from '@/utils/errors';

import { Banner } from '../../utility/Banner';
import { Button } from '../../core-ui/Button';

import S from './styles.module.scss';
import { returns_exchangeMode_activeAlert } from "@/lang/__generated__/ahnu/returns_exchangeMode_activeAlert";
import { returns_exchangeMode_returnToOrderSummary } from "@/lang/__generated__/ahnu/returns_exchangeMode_returnToOrderSummary";

export interface IExchangeBannerProps {
  /**
   * A model describing the state of the returns flow.
   * @see {@link ReturnsFlowStateModel}
   */
  flowState: ReturnsFlowStateModel;
}

/** Banner that displays on top of the page when Exchange Mode is enabled. */
export const ExchangeBanner: FunctionComponent<IExchangeBannerProps> = ({
  flowState
}) => {
  const router = useRouter();

  const handleCancel = useCallback(async () => {
    if (flowState) {
      await flowState.abortExchange();
      router.push(flowState.getCurrentStepURL());
    } else {
      throw new InvalidStateError(
        'Cannot cancel exchange: returns flow state not available.'
      );
    }
  }, [flowState]);

  return (
    <Banner className={S.exchangeBanner} closable={false}>
      <div>{msg(returns_exchangeMode_activeAlert)}</div>

      <div className={S.linkContainer}>
        <Button variant="link" className={S.link} onClick={handleCancel}>
          {`${msg(returns_exchangeMode_returnToOrderSummary)} >>`}
        </Button>
      </div>
    </Banner>
  );
};
