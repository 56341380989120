'use client';

import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { PageHead } from '../../meta-data/PageHead';
import { StructuredData } from '../../meta-data/StructuredData';
import { BackToTopButton } from '../../navigation/BackToTopButton';
import { Footer } from '../../navigation/Footer';
import { Header } from '../../navigation/Header';
import { SkipToLink, SkipToLinkType } from '../../navigation/SkipToLink';
import { IMainProps } from './IMainProps';

import { PageProvider } from '../../templates/context/PageProvider';
import NotificationContext from '../../utility/notifications/controller/NotificationContext';
import useNotificationController from '../../utility/notifications/controller/useNotificationController';
import { NotificationBanner } from '../../utility/notifications/components/NotificationBanner';

import { Osano } from '../../cookie-preferences';

import { Unprintable } from '../../utility/Unprintable';
import S from './styles.base.module.scss';

/**
 * The main layout of the website with header, footer and content.
 *
 * @param props - Contains the content children, and CoreMedia Data.
 */
const DefaultMainLayout = observer(function Main({
  children,
  navigationContent,
  hideFooterNavigation,
  page,
  paddingBottom
}: IMainProps) {
  // All notification logic is encapsulated in the useNotificationController hook
  // to keep this component light and simple.
  const notificationController = useNotificationController();

  return (
    <PageProvider page={page}>
      <StructuredData page={page} />
      <Osano />
      <PageHead page={page} />
      <div
        className={S.layout}
        style={{ ...(paddingBottom && { paddingBottom }) }}
      >
        <NotificationContext.Provider value={notificationController}>
          <Unprintable>
            <SkipToLink type={[SkipToLinkType.Main, SkipToLinkType.Footer]} />
            <Header navigationContent={navigationContent} page={page} />
          </Unprintable>
          <main id={SkipToLinkType.Main} className={S.main}>
            <Unprintable>
              <NotificationBanner />
            </Unprintable>
            {children}
          </main>
          <Unprintable>
            <BackToTopButton />
            <Footer
              navigationContent={navigationContent}
              hideFooterNavigation={hideFooterNavigation}
            />
          </Unprintable>
        </NotificationContext.Provider>
      </div>
    </PageProvider>
  );
});

export default DefaultMainLayout;
