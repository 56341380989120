import type { FunctionComponent, PropsWithChildren } from 'react';
import { DynamicExperience } from '@/react/components/personalization/DynamicExperience';
import { Decision } from '@/react/components/personalization/Decision';

import type { IPage } from '@/services/models/Page';
import type { Nullable } from '@/type-utils';
import S from './styles.base.module.scss';

/**
 * Describes the props for the {@link '@/react/components/navigation/Header/personalization/ABTests/StickyHeaderAB' StickyHeaderAB} component.
 * Children can be any valid children, but should be a {@link '@/react/components/navigation/Header' Header} component.
 */
interface IStickyHeaderABProps extends PropsWithChildren {
  /** The current page on which the ab test is rendered. */
  page?: Nullable<IPage>;
}

/**
 * Houses the sticky header experience and decisions used with an active AB test.
 *
 * WARNING: This component will only work if an AB test with the ID "ab-header-position" is active, and at least one decision with the corresponding ID is available.
 */
export const StickyHeaderAB: FunctionComponent<IStickyHeaderABProps> = ({
  children: header,
  page
}) => {
  return (
    <DynamicExperience experienceID="ab-header-position" page={page}>
      <Decision id="ab-header-decision-sticky" fallback={header}>
        {() => <div className={S.sticky}>{header}</div>}
      </Decision>
      <Decision id="ab-header-decision-static" fallback={header}>
        {() => <div className={S.static}>{header}</div>}
      </Decision>
    </DynamicExperience>
  );
};
