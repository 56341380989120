import { FunctionComponent, ReactNode, useState, useEffect } from 'react';

import { classes } from '@/next-utils/css-utils/scss-utils';
import { Nullable } from '@/type-utils';
import {
  Decision,
  ExperienceID,
  PersonalizationService
} from '@/services/isomorphic/PersonalizationService';
import { observer } from 'mobx-react-lite';
import { IPage } from '@/services/models/Page';
import LoggerService from '@/services/isomorphic/LoggerService';
import SkeletonLoader from '../../utility/SkeletonLoader';
import { ExperienceProvider } from './ExperienceProvider';
import S from './styles.module.scss';

export interface IDynamicExperienceProps {
  /**
   * A custom fallback that can be rendered in place of a loading decision.
   *
   * The default is a skeleton loader.
   */
  fallback?: ReactNode;

  /**
   * The children of the `DynamicExperience`, should contain Decisions that will
   * be selectively rendered based on whether they have been chosen or not.
   */
  children: ReactNode | Array<ReactNode>;

  /**
   * Classes passed in to style the experience container.
   */
  className?: string;

  /**
   * The experience id that is going to be queried to get
   * the proper decision to show.
   */
  experienceID: ExperienceID;

  /**
   * The page that the experience is being rendered on.
   */
  page?: Nullable<IPage>;
}

/**
 * Manages the shared state of the children decisions. The decisions will wait until
 * a choice has been selected by our `PersonalizationService` and then render appropriately
 * based on that choice.
 *
 * Shouldn't care what children look like
 * only manages shared state.
 */
export const DynamicExperience: FunctionComponent<IDynamicExperienceProps> =
  observer(
    ({
      experienceID,
      children,
      className,
      fallback = <SkeletonLoader />,
      page
    }) => {
      // While the decision is unknown it is null.
      const [decision, setDecision] = useState<Nullable<Decision>>(null);

      useEffect(() => {
        const getDecision = async (): Promise<void> => {
          try {
            const decision =
              await PersonalizationService.getExperienceForCurrentUser(
                experienceID,
                page
              );
            setDecision(decision);
          } catch (error) {
            LoggerService.warn((error as Error).message);
          }
        };

        getDecision();
      }, []);

      return (
        <ExperienceProvider experienceID={experienceID} decision={decision}>
          {/**
           * It would be cool if we can support animating layout shifts, in the event that
           * children vary in size. Framer Motion might be the right tool, but it's not as
           * simple as `<motion.div layout>`.
           */}
          <div className={classes(S.dynamicExperience, className)}>
            {children}
          </div>
        </ExperienceProvider>
      );
    }
  );
