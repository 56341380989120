import { useCallback, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import UserService from '@/services/isomorphic/UserService';
import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import { useGlobalContext } from './useGlobalContext';

/**
 * Interface for the useLogout hook.
 */
interface IUseLogout {
  /**
   * Async function to log out the user. Clears the cart and redirects to the logout redirect location.
   * @returns A promise that resolves when the user is logged out.
   */
  logOut: () => Promise<void>;
  /**
   * Whether the user is currently logging out.
   */
  loading: boolean;
  /**
   * Whether the user is currently logged in.
   */
  isLoggedIn: boolean;
}

/**
 * Hook housing the logout flow: logging out the user, clearing the cart, and redirecting to the logout redirect location.
 * @returns The logout function, loading state, and logged in state.
 */
export const useLogout = (): IUseLogout => {
  const { user, cart } = useGlobalContext();

  const router = useRouter();

  const [isLoggedIn, setIsLoggedIn] = useState(user?.isLoggedIn ?? false);

  const [loading, setLoading] = useState(!user);

  useEffect(
    function setUser() {
      if (user) {
        setIsLoggedIn(user.isLoggedIn);
        setLoading(false);
      }
    },
    [user]
  );
  const logOut = useCallback(async () => {
    setLoading(true);

    try {
      await UserService.signOut();
      await cart.empty();
      setIsLoggedIn(false);

      const redirectURL = ConfigurationService.getConfig('user').getSetting(
        'logoutRedirectLocation'
      ).value;

      await router.push(redirectURL);
    } finally {
      setLoading(false);
    }
  }, [cart, router]);

  return {
    logOut,
    loading,
    isLoggedIn
  };
};
