import {
  useContext,
  useState,
  createContext,
  FC,
  PropsWithChildren,
  useCallback
} from 'react';
import { INavigationContent } from '@/services/models/Content';
import { Nullable } from '@/type-utils';

/**
 * The context for the mobile drawer. Contains the drawer state, callback functions, and necessary
 * navigation content for nested navigation items.
 */
interface IMobileDrawerContext {
  /**
   * Whether the current drawer is open.
   */
  isOpen: boolean;
  /**
   * Opens the drawer.
   */
  openDrawer: () => void;
  /**
   * Closes the drawer.
   */
  closeDrawer: () => void;
  /**
   * Closes the current drawer and previous drawers.
   */
  closeAll: () => void;
  /**
   * Navigation content housing the category information used on nested navigation items.
   */
  navigationContent: Nullable<INavigationContent>;
}

export const MobileDrawerContext = createContext<IMobileDrawerContext>({
  isOpen: false,
  openDrawer: () => {},
  closeDrawer: () => {},
  closeAll: () => {},
  navigationContent: null
});

interface IMobileDrawerProviderProps {
  /**
   * Navigation content housing the category information used on nested navigation items.
   */
  navigationContent: Nullable<INavigationContent>;
}

/**
 * Initializes a new mobile drawer state that can be set to mobile drawer context.
 * Also provides access to previously opened drawers. Should not be used directly.
 * @param props - NavigationContent that will be consumed by future uses of useMobileDrawer.
 * @returns A new MobileDrawerContext object.
 */
const useMobileDrawerContext = ({
  navigationContent
}: IMobileDrawerProviderProps): IMobileDrawerContext => {
  const [isOpen, setOpen] = useState(false);

  const openDrawer = useCallback(() => setOpen(true), []);
  const closeDrawer = useCallback(() => setOpen(false), []);

  const closeAll = useCallback(() => {
    closeDrawer();
  }, [closeDrawer]);

  return {
    isOpen,
    openDrawer,
    closeDrawer,
    navigationContent,
    closeAll
  };
};

/**
 * Top-most component defined at the Header level. Initializes the
 * default drawer state. To be used in conjunction with useMobileDrawer.
 * @see useMobileDrawer
 */
export const MobileDrawerProvider: FC<
  PropsWithChildren<IMobileDrawerProviderProps>
> = ({ navigationContent, children }) => {
  const value = useMobileDrawerContext({ navigationContent });

  return (
    <MobileDrawerContext.Provider value={value}>
      {children}
    </MobileDrawerContext.Provider>
  );
};

/**
 * Returns the active context for the mobile drawer.
 * @see MobileDrawerProvider
 * @returns The currently active mobile drawer context.
 * @throws If used outside of the MobileDrawerProvider.
 */
export const useMobileDrawer = (): IMobileDrawerContext => {
  const context = useContext(MobileDrawerContext);
  if (!context) {
    throw new Error(
      'useMobileDrawerContext must be used within a MobileDrawerProvider'
    );
  }
  return context;
};
